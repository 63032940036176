@keyframes PopOverAnim {
    0% {
        top: 150px;
        opacity: 1;
        display: flex;
    }
    70% {
        opacity: 1;
        display: flex;
    }
    100% {
        top: 800px;
        opacity: 0;
        display: none
    }
}

@keyframes PreoccupationAnimLeft {
    0% {
        opacity: 1;
    }

    70% {
        opacity: 0;
        transform: translate(-70px, -20px) scale(0.5);;
    }

    72% {
        transform: translate(0px, 0px) scale(1);;
    }

    100% {
        opacity: 1;
    }
}

@keyframes PreoccupationAnimRight {
    0% {
        opacity: 1;
    }

    70% {
        opacity: 0;
        transform: translate(70px, -20px) scale(0.5);
    }

    72% {
        transform: translate(0px, 0px) scale(1);;
    }

    100% {
        opacity: 1;
    }
}

@keyframes PreoccupationAnimLeftTab {
    0% {
        opacity: 1;
    }

    70% {
        opacity: 0;
        transform: translate(-110px, -20px) scale(0.5);
    }

    72% {
        transform: translate(0px, 0px) scale(1);
    }

    100% {
        opacity: 1;
    }
}

@keyframes PreoccupationAnimRightTab {
    0% {
        opacity: 1;
    }

    70% {
        opacity: 0;
        transform: translate(110px, -20px) scale(0.5);
    }

    72% {
        transform: translate(0px, 0px) scale(1);
    }
    
    100% {
        opacity: 1;
    }
}


@keyframes shaking {
    0% {
        width: 160px;
        height: 42px;
    }
    50% {
        width: 170px;
        height: 52px;
    }
    100%{
        width: 160px;
        height: 42px;
    }
}

@-webkit-keyframes shaking {
    0% {
        width: 160px;
        height: 42px;
    }
    50% {
        width: 170px;
        height: 52px;
    }
    100%{
        width: 160px;
        height: 42px;
    }
}

@keyframes PopOverUp {
    0% {
        bottom: -100%;
    }
    100% {
        bottom: 0;
    }
}

@keyframes blinking {
    0% {
        fill: #d4cfc3;
    }
    50% {
        fill: black;
    }
    100%{
        fill: #d4cfc3;
    }
}

@-webkit-keyframes blinking {
    0% {
        fill: #d4cfc3;
    }
    50% {
        fill: black;
    }
    100%{
        fill: #d4cfc3;
    }
}

@keyframes Fading {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-webkit-keyframes Fading {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.fadeInUp-enter {
    bottom: -100%;
}

.fadeInUp-enter-active {
    bottom: 0%;
    transition: all 400ms;
}

.fadeInUp-exit {
    bottom: 0%;
    transition: all 400ms;
    -webkit-transition: all 400ms;
}

.fadeInUp-exit-active {
    bottom: -100%;
    transition: all 400ms;
    -webkit-transition: all 400ms;
}

.swipeToRight-enter {
    left: 100%;
}

.swipeToRight-enter-active {
    left: 0.01%;
    transition: all 300ms;
    -webkit-transition: all 300ms;
}

.swipeToRight-exit {
    left: 0.01%;
}

.swipeToRight-exit-active {
    left: -100%;
    transition: all 300ms;
}

.swipeToRightResults-enter {
    left: 100%;
    opacity: 0;
}

.swipeToRightResults-enter-active {
    left: 0.01%;
    transition: all 300ms;
    opacity: 1;
}

.swipeToRightResults-exit {
    left: 0.01%;
}

.swipeToRightResults-exit-active {
    left: -100%;
    transition: all 300ms;
    -webkit-transition: all 300ms;
}


/* Hide scrollbar for Chrome, Safari and Opera */

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}


/* Hide scrollbar for IE, Edge add Firefox */

.scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* Firefox */
}

.bubbles-container {
    position: relative;
    width: 100%;
    height: 120px;
}

@media screen and (min-width: 768px) {
    .bubbles-container {
        position: relative;
        width: 95%;
        height: 150px;
    }
}

.images-container {
    width: 200px;
    height: 240px;
    display: flex;
    align-items: center;
}

@media (min-width: 768px){
        .images-container {
            width: 400px;
            height: 400px;
        }
}

.images-item {
    align-items: center;
    justify-content: center;
    display: flex;
    animation: Fading 400ms forwards;
}

.blinking {
    animation: blinking 2s infinite;
}

.shaking {
    animation: shaking 0.5s;
}
